import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Hero from "../components/hero"
import Header from "../components/layout/header"
import Footer from "../components/layout/footer"
import SEO from "../components/seo"
// import PoisGrid from "../components/pois/grid"
import Sections from "../components/homepage/sections"
// import Section from "../components/section"
import Link from "../components/link"

import "../styles/global.css"

const Homepage = ({ data }) => {
  const title = get(data, "site.siteMetadata.title")
  // const pois = get(data, "allContentfulPoi.nodes", [])
  const heading = get(data, "contentfulHomepage.heading")
  const sections = get(data, "contentfulHomepage.sections.section", [])
  const subheading = get(data, "contentfulHomepage.subheading")
  const backgroundImage = get(data, "contentfulHomepage.backgroundImage")
  const seoImage = get(backgroundImage, "fluid.src", "")

  const rootClassName = `
    container w-full flex flex-col justify-between items-start
    px-6 pb-6 pt-16 text-white text-left text-lg
    sm:items-center md:w-3/4 sm:py-16 md:py-32 sm:text-center
  `

  const sectionTitleKeywords = sections
    .map(({ title }) => title.toLowerCase())
    .join(', ')

  const keywords = sectionTitleKeywords ?
    `info, prices, pricing, prijzen, ${sectionTitleKeywords}` :
    'info, prices, pricing, prijzen'

  return (
    <>
      <SEO title="Home" image={seoImage} keywords={keywords} />
      <Hero image={backgroundImage.fluid}>
        <Header transparent title={title} />

        <article className={rootClassName}>
          {heading && (
            <h2 className="m-0 text-xl md:mb-2 sm:text-2xl md:text-3xl">
              {heading}
            </h2>
          )}
          {subheading && (
            <p className="m-0 text-sm sm:text-md md:text-xl">{subheading}</p>
          )}
          <Link
            className="inline-block mt-4 button-transparent"
            to="/info-pricing"
          >
            Info / Prijzen
          </Link>
        </article>
      </Hero>
      <Sections data={sections} />
      {/* <Section title="Nearby" subtitle="Place of interests">
        <PoisGrid pois={pois} hideReadMore />
      </Section> */}

      <a
        style={{ top: '25%' }}
        className="fixed right-0 block shadow-lg rounded-l-md" href="https://www.visitlimburg.be/nl/overnachten/huys" target="_blank">
        <img className="w-12 mb-0 rounded-l-md" src="/toerisme-vlaanderen.jpg" alt="Toerisme vlaanderen" />
      </a>

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPoi(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        title
        type
        url
      }
    }
    contentfulHomepage(contentful_id: { eq: "46wCUREOaTGSjVqnmHllOC" }) {
      heading
      subheading
      backgroundImage {
        fluid(maxWidth: 1920, maxHeight: 768) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sections {
        section {
          title
          subtitle
          link
          body {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 1920, maxHeight: 1024) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default Homepage
