import React from "react"
import BackgroundImage from "gatsby-background-image"

import styles from "./hero.module.css"

const Hero = ({ image, children }) => {
  const stack = [
    image,
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1))`,
  ].reverse()

  return (
    <BackgroundImage
      Tag="section"
      className={styles.root}
      fluid={stack}
      backgroundColor="#040e18"
    >
      {children}
    </BackgroundImage>
  )
}

export default Hero
