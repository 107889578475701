import React from "react";

import Section from './section';

const Sections = ({ data }) => {
    if (!data || !data.length > 0) return null;

    return (
        <section>
            {data.map((section, i) => <Section key={i} index={i} {...section} />)}
        </section>
    )
}

export default Sections
