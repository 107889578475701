import React from "react"
import Img from "gatsby-image"
import get from "lodash/get"
import Link from "../link"

const HeadlineSection = ({ title, html }) => {
  const contentClass = `
        html whitespace-pre-line no-paragraph-margin
        w-full text-left font-light text-md
        sm:text-center sm:text-lg md:text-2xl sm:px-8 md:px-16 xl:px-24`

  return (
    <article className="text-center text-gray-800 bg-gray-300">
      <div className="container p-6 sm:px-6 sm:py-16">
        <h4 className="mb-4 text-xs font-bold text-left uppercase sm:text-center text-primary-700">
          {title}
        </h4>
        <div
          className={contentClass}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </article>
  )
}

const Section = ({ index, title, subtitle, link, image, body }) => {
  const html = get(body, "childMarkdownRemark.html")
  const isFirst = index === 0
  const isEven = index % 2 === 0

  const innerClass = `
        flex flex-col
        ${isEven ? "md:flex-row" : "md:flex-row-reverse"}
    `

  const contentClass = `
        w-full md:w-2/5 font-light
        ${isEven ? "md:pr-8 xl:pr-24" : "md:pl-8 xl:pl-16"}
    `

  const titleClass = `
        font-normal
        ${
          isFirst
            ? "text-xs text-gray-600 font-medium uppercase"
            : "text:xl sm:text-3xl"
        }
    `

  if (!title) return null
  if (isFirst) return <HeadlineSection title={title} html={html} />

  return (
    <article className="border-t border-gray-300 border-solid">
      <div className="container px-6 py-6 sm:py-16">
        {subtitle && (
          <h4 className="mb-2 text-xs font-medium uppercase text-primary-700">
            {subtitle}
          </h4>
        )}
        <h3 className={titleClass}>{title}</h3>

        <div className={innerClass}>
          <div className={contentClass}>
            <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
            {link && (
              <Link className="inline-block mt-4 button-black" to={link}>
                Meer info
              </Link>
            )}
          </div>

          {image && (
            <Img className="w-full mt-4 md:mt-0 md:w-3/5" fluid={image.fluid} />
          )}
        </div>
      </div>
    </article>
  )
}

export default Section
